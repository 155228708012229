import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import * as Icon from 'react-feather'
import Layout from "../components/layout"
import Seo from "../components/seo"

import GrandTourLogo from '../images/grand-tour/grand-tour-hero.svg'

const readGuides = [
  {
    text: "Log Reading & Add Books",
    url: "/guides/goread/log-reading-adding-books.pdf",
    fileType: "pdf"
  },
  {
    text: "Activity Feeds",
    url: "/guides/goread/class-pupil-activity-feeds.pdf",
    fileType: "pdf"
  },
  {
    text: "Class Data",
    url: "/guides/goread/class-data.pdf",
    fileType: "pdf"
  },
  {
    text: "Group Books",
    url: "/guides/goread/group-books.pdf",
    fileType: "pdf"
  },
  {
    text: "Adding Reading Bands",
    url: "/guides/goread/reading-bands.pdf",
    fileType: "pdf"
  },
  {
    text: "Getting Started With Parents",
    url: "/guides/goread/getting-started-with-parents.pdf",
    fileType: "pdf"
  },
  {
    text: "Hints & Tips",
    url: "/guides/goread/hints-tips.pdf",
    fileType: "pdf"
  },
  {
    text: "Pupil Guide",
    url: "/guides/goread/pupils-guide.pdf",
    fileType: "pdf"
  }
]
const readParentsGuides = [
  {
    text: "Parent Guide (Mobile App)",
    url: "/guides/goread/parent-quick-start-guide.pdf",
    fileType: "pdf"
  },
  {
    text: "Parent Guide (Web App)",
    url: "/guides/goread/parent-guide-web.pdf",
    fileType: "pdf"
  },
  {
    text: "Posters",
    url: "/resources/parent-app-posters-v2.pdf",
    fileType: "pdf"
  },
  {
    text: "Parents Intro Slides",
    url: "/resources/parents-boomreader-Introduction.pptx",
    fileType: "pptx"
  },
  {
    text: "Editable parent letter",
    url: "/resources/editable-parent-letter.docx",
    type: "docx"
  }
]

const writeGuides = [
  {
    text: "School Administrator Guide",
    url: "/guides/gowrite/administrator-guide.pdf",
    fileType: "pdf"
  },
  {
    text: "School Staff Guide",
    url: "/guides/gowrite/staff-guide.pdf",
    fileType: "pdf"
  },
  {
    text: "Pupil Guide",
    url: "/guides/gowrite/pupil-guide.pdf",
    fileType: "pdf"
  },
]

const resources = [
  {
    text: "Reading Hero Certificate",
    url: "/resources/reading-hero-certificate.pdf",
    fileType: "pdf"
  },

  {
    text: "Boomreader Pupil Intro Slides",
    url: "/resources/boomreader-pupil-intro-slides.pptx",
    fileType: "pptx"
  }
]

const summerResources = [
  {
    text: "Class Presenter (PowerPoint)",
    url: "/resources/summer-readathon/class-presentation.pptx",
    fileType: "pptx"
  },
  {
    text: "Class Presenter (PDF)",
    url: "/resources/summer-readathon/class-presentation.pdf",
    fileType: "pdf"
  },
  {
    text: "Posters",
    url: "/resources/summer-readathon/posters.pdf",
    fileType: "pdf"
  },
  {
    text: "Parent Flyer (Editable)",
    url: "/resources/summer-readathon/parent-flyer.docx",
    fileType: "docx"
  },
  {
    text: "Parent Flyer",
    url: "/resources/summer-readathon/parent-flyer.pdf",
    fileType: "pdf"
  },
]

const IndexPage = () => (
  <Layout>
    <Seo title="Guides" />
    <div className="container">

      <section className="mb">
        <div className="homeIntro mb">
          <h1>Guides & Resources</h1>
          <div className="homeIntro_help">
            <h2 className="txt-default">Need Help?</h2>
            <div className="homeIntro_links">
              <a className="link" href="mailto:support@boomhub.app">Contact Us</a>
            </div>
          </div>
        </div>

        <div className="promoBanners">
          <div className="promoBanners_main">
            {/* <Link to="/write-to-raise" className="graBanner wtr">
              <div className="graBanner_desc">
                <div>
                  <StaticImage src="../images/wtr/brian-shapes.png" alt="Brian Blessed" height={60} />
                </div>
                <span className="h2">Write To Raise</span>
              </div>
              <Icon.ArrowRight className="graBanner_icn" />
            </Link> */}
            <Link to="/grand-tour" className="graBanner">
              <div className="graBanner_desc">
                <span className="h2">Grand Tour - Summer 2023</span>
              </div>
              <Icon.ArrowRight className="graBanner_icn" />
            </Link>
            <Link to="/guides#ParentApp" className="graBanner">
              <div className="graBanner_desc">
                <span className="h2">Parent App Resources</span>
              </div>
              <Icon.ArrowRight className="graBanner_icn" />
            </Link>
          </div>
          {/* <Link to="/guides#ParentApp" className="graBanner parentApp"><span className="h-small">New Parent App Resources</span> <Icon.ArrowDown className="graBanner_icn" /></Link> */}
        </div>
      </section>

    </div>
  </Layout>
)

export default IndexPage
